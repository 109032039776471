<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green" indeterminate></v-progress-circular></v-overlay>
    <!-- Modal Components::Begin -->
    <NewRMRequisition :dialog.sync="editDialog" :data="blendPlan" @onSave="onSave" />

    <v-container fluid class="px-6 py-3">
      <v-row>
        <!-- #region Card section 1 -->
        <v-col cols="12" :class="{ 'mb-5': currentPage.permissions.length <= 0 }">
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="px-0 py-0">
              <v-row class="pa-4 ms-3">
                <!-- #region Plan Name -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">RM Requisition</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.name }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Production Date -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Production Date</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ formatDate(this.blendPlan.productionDate) }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Production Quantity -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Quantity</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.productionQuantity }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Blend Code Name -->
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Blend Code Name</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.blendCodeName }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Blend Source Number -->
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Source Number</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.sourceNumber }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <v-col>
                  <v-btn @click="editDialog = true" dark class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow">
                    Edit
                  </v-btn>
                </v-col>
                <!-- <v-col v-show="this.planGenerationStatus">
                  <action-button :actions="currentPage.actions_allowed" @onClick="onActionClick" />
                </v-col> -->
                <!-- #endregion -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- #endregion -->

        <!-- #region Card section 2 -->
        <!-- -->
        <v-col cols="12" v-if="currentPage.permissions.length > 0">
          <v-card class="card-shadow border-radius-xl">
            <v-card-text>
              <v-form ref="frm">
                <v-card-text class="px-0 py-0">
                  <v-row class="pa-2">
                    <!-- #region PO Stock Selection -->
                    <v-col class="shrink">
                      <label class="text-md text-typo ms-1" style="white-space: nowrap">Use PO Stock</label>
                      <v-row class="mt-5 align-center ms-1">
                        <v-switch
                          :ripple="false"
                          class="mt-0 pt-0 ms-3 switch"
                          v-model="selectedData.poStockSwitch"
                          hide-details
                          color="#49ab5cf2"
                          inset
                          :label="POStockSelection"
                        ></v-switch>
                      </v-row>
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Company warehouse selector -->
                    <v-col cols="3">
                      <label class="text-md text-typo ms-1">Select other warehouses</label>
                      <v-select
                        v-model="selectedData.companyWarehouses"
                        :items="filteredCompanyWarehouses"
                        item-text="name"
                        item-value="id"
                        dense
                        flat
                        filled
                        solo
                        class="input-style font-size-input text-light-input placeholder-light select-style mt-2"
                        outlined
                        chips
                        multiple
                        single-line
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ companyWarehouses.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle">
                            <v-list-item-action>
                              <v-icon :color="companyWarehouses.length > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title> Select All </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Search Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn class="bg-success py-5 px-0 font-weight-bold text-capitalize btn-primary bg-success" @click="searchData">
                        <v-icon size="20" class="text-white"> fas fa-search </v-icon>
                      </v-btn>
                    </v-col>
                    <!-- </v-row> -->

                    <!-- <v-row> -->
                    <!-- #region Autogenerate Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="availableStock.length > 0 && !isPlanSaved"
                        @click="generatePlan"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-3 px-6"
                        >Auto Generate Plan</v-btn
                      >
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Rearrange Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="isPlanSaved"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow"
                        @click="reAssignPlan()"
                      >
                        <v-icon size="20" class="text-white mr-1"> fas fa-retweet </v-icon>
                        Re-Arrange
                      </v-btn>
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Save Button -->
                    <!-- <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="selectedData.selectedStocks.length > 0"
                        @click="saveDetails"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-3 px-6"
                        >Save</v-btn
                      >
                    </v-col> -->
                    <!-- #endregion -->
                    <!-- #region Download Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="isPlanSaved"
                        @click="downloadPlan()"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow"
                      >
                        <v-icon size="20" class="text-white mr-1"> fas fa-cloud-download-alt </v-icon>
                        Download
                      </v-btn>
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Generate Provision Plan Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="isPlanSaved"
                        @click="convertToProvisionPlan()"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow"
                      >
                        <v-icon size="20" class="text-white mr-1"> fas fa-cloud-download-alt </v-icon>
                        Convert to Provisional Plan
                      </v-btn>
                    </v-col>
                    <!-- #endregion -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        @click="showSelected()"
                        dark
                        class="font-weight-bold text-capitalize btn-secondary bg-secondary py-5 px-5 shadow"
                      >
                        <!-- <v-icon size="20" class="text-white mr-1"> fas fa-cloud-download-alt </v-icon> -->
                        Show Selected
                      </v-btn>
                    </v-col>
                    <!-- #endregion -->
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- #endregion -->

        <!-- #region Table Section -->
        <!--  -->
        <v-col cols="12" v-if="currentPage.permissions.length > 0">
          <v-card class="card-shadow border-radius-xl">
            <!-- AG Grid  -->
            <ag-grid-vue
              rowHeight="40"
              style="height: 750px"
              class="ag-theme-alpine"
              rowSelection="multiple"
              @grid-ready="onGridReady"
              :rowData="availableStock"
              :columnDefs="headers"
              :defaultColDef="defaultColDef"
              :rowMultiSelectWithClick="true"
              headerHeight="100"
              @first-data-rendered="onFirstDataRendered"
            >
            </ag-grid-vue>
            <!-- NOTE
            onGridReady = should call in mounted hook.
            defaultColDef = col definition with common function
            columnDefs = only field and value
             -->
            <!-- AG Grid  -->
          </v-card>
        </v-col>

        <!-- #endregion -->
      </v-row>
      <!--  -->
      <!--  -->
      <!-- Show Selected List -->
      <v-dialog v-model="showSelectedList" persistent fullscreen transition="dialog-bottom-transition">
        <v-card class="card-shadow border-radius-xl">
          <v-card-title>
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-col cols="10" class="text-wrap">
                <div class="grid-container">
                  <div class="grid-item" v-for="item in calculatedBlendQtyDetails" :key="item.itemName">
                    {{ item.itemName }} :
                    {{ item.requiredQty - item.selectedQty }}
                  </div>
                </div>
              </v-col>

              <v-col align="right" justify="center">
                <v-btn
                  @click="closeListDialogue"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6 mb-5 mt-5 mr-3"
                  >Go Back to Select More
                </v-btn>

                <v-btn
                  @click="saveDetails"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6 mb-5 mt-5"
                  >Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text
            ><v-row>
              <v-col>
                <v-col>
                  <v-radio-group v-model="enter_field" row @change="changeEnterField()">
                    <v-radio label="Enter by Weight" color="red" value="weight"></v-radio>
                    <v-radio label="Enter by bags" color="red" value="bags"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-col>
            </v-row>
            <ag-grid-vue
              v-if="enter_field != ''"
              v-model="saveList"
              rowHeight="40"
              style="height: 500px"
              class="ag-theme-alpine"
              @grid-ready="onGridReady1"
              :rowData="selectedData.selectedStocks"
              :columnDefs="headers2"
              :rowMultiSelectWithClick="true"
              :defaultColDef="defaultColDef"
              :getRowId="getRowId"
            >
            </ag-grid-vue>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Dialogue Remove Selected Confirm -->
      <v-dialog v-model="dialogueRemove" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <p class="text-center">Do you want to Remove this item?</p>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="cancelRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="confirmRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6"
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialogue Remove All Confirm -->
      <v-dialog v-model="dialogueAllRemove" max-width="500px" persistent>
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title class="pt-0 text-h5 text-typo justify-center">
            <p class="text-center">Do you want to Remove All item?</p>
          </v-card-title>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="cancelAllRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
              >No</v-btn
            >

            <v-btn
              @click="confirmAllRemove"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6"
              >Yes</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <blend-plan-qty-details :dialog.sync="dialog" :data="calculatedBlendQtyDetails" />
    </v-container>
  </div>
</template>

<script>
import NewRMRequisition from "../components/NewRMRequisition.vue";
import ActionButton from "../../../../components/ActionButton.vue";
import CompanyWarehouseService from "../../../master/CompanyWareHouse/api";
import api from "../api";
import { TABLE_HEADERS_AG_LIST_1, TABLE_HEADERS_AG_LIST_2 } from "../constants";
import BlendPlanQtyDetails from "../../components/BlendPlanQtyDetails.vue";
// import NewRMRequisition from "../components/NewRMRequisition.vue";
import { AgGridVue } from "ag-grid-vue";
export default {
  name: "BlendPlanDetails",
  components: {
    ActionButton,
    NewRMRequisition,
    BlendPlanQtyDetails,
    AgGridVue,
  },
  data() {
    return {
      editDialog: false,
      blendPlan: null,
      dialog: false,
      blendPlanDetails: [],
      companyWarehouses: [],
      selectedData: {
        companyWarehouses: [],
        selectedStocks: [],
        poStockSwitch: false,
      },
      blendQtyDetails: [],
      calculatedBlendQtyDetails: [],
      availableStock: [],
      overlay: false,
      requiredQuantity: 0,
      isPlanSaved: false,
      currentPage: {},
      enter_field: "bags",
      headers: TABLE_HEADERS_AG_LIST_1,
      // AG GRID
      gridApi: null,
      defaultColDef: {
        filter: "agTextColumnFilter",
        suppressMovable: true,
        sortable: true,
        resizable: true,
        filterParams: { suppressAndOrCondition: true },
      },

      columnApi: null,
      getRowId: null,
      gridApi1: null,
      columnApi1: null,
      headers2: TABLE_HEADERS_AG_LIST_2,
      // AG GRID
      gridApi: null,
      showSelectedList: false,
      removeObj: null,
      dialogueRemove: false,
      dialogueAllRemove: false,
      saveList: [],
    };
  },
  computed: {
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
    selectAll() {
      return this.selectedData.companyWarehouses.length === this.companyWarehouses.length;
    },
    selectSome() {
      return this.companyWarehouses.length > 0 && !this.selectAll;
    },
    filteredCompanyWarehouses() {
      return this.companyWarehouses.filter((x) => x.id !== this.blendPlan?.companyWarehouseId);
    },
    POStockSelection() {
      return this.selectedData.poStockSwitch ? "Yes" : "No";
    },
  },
  watch: {
    blendPlan(val) {
      if (!val) {
        this.$router.go(-1);
      }
    },
    showSelectedList(val) {
      if (val) {
        this.showBlendQtyDetails();
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.selectedData.companyWarehouses = [];
        } else {
          // TODO Rewrite select all item logic
          //   this.selectedData.companyWarehouses = this.companyWarehouses.slice();

          //   const data = Object.values(
          //     data.reduce((a, b) => {
          //       console.log(a);
          //     })
          //   );

          this.selectedData.companyWarehouses = this.filteredCompanyWarehouses;
        }
      });
    },
    onSave(data) {
      this.blendPlan = data;
      this.editDialog = false;
      this.goBack();
    },
    checkCardColor(item) {
      let _item;
      // Checking if item is from Inventory Stock or PO Stock
      if (item.inventoryId) {
        _item = this.selectedData.selectedStocks.find((x) => x.inventoryId == item.inventoryId);
      } else {
        _item = this.selectedData.selectedStocks.find((x) => x.purchaseChildId == item.purchaseChildId);
      }

      if (_item) {
        if (item.totalNetKgs < _item.requiredQuantity) {
          return "rgba(240, 89, 89, 0.2)";
        } else {
          return "rgba(129, 242, 116, 0.2)";
        }
      } else {
        return "white";
      }
    },
    qtyBoxValidation(params) {
      let data = params.data;

      if (
        (parseFloat(data.requiredQuantity) > parseFloat(data.totalNetKgs) && data.requiredQuantity !== "") ||
        (parseFloat(data.requiredPackages) > parseFloat(data.noOfPackages) && data.requiredPackages !== "")
      ) {
        return false;
      }
      return true;
    },
    itemRowBackground: function (item) {
      return "style-1";
    },

    isCardSelected(item) {
      const Index = this.selectedData.selectedStocks.indexOf(item);

      if (Index > -1) {
        return false;
      } else {
        return true;
      }
    },
    checkStockType(item) {
      if (item) {
        return "Inventory";
      } else {
        return "PO";
      }
    },
    checkStockLevel(item) {
      const _item = this.availableStock.find((x) => x.inventoryId == item.inventoryId);

      if (_item) {
        return item.totalNetKgs === _item.totalNetKgs ? "orange" : "green";
      }
    },
    requiredQtyCalc(event, field) {
      if (field == "weight") {
        // Setting Data to UI
        let rowNode = this.gridApi1.getRowNode(event.data.id);
        let rowData = rowNode.data;
        const _noPackages = Math.ceil(parseFloat(rowData.requiredQuantity) / parseFloat(rowData.netKgs));
        rowData.requiredQuantity = parseFloat(rowData.requiredQuantity);
        rowData.requiredPackages = _noPackages;
        rowNode.setData({ ...rowData });
        let _index = this.selectedData.selectedStocks.findIndex((el) => el.id == rowData.id);
        // Setting Data to Variable
        this.selectedData.selectedStocks[_index]["requiredQuantity"] = rowData.requiredQuantity;
        this.selectedData.selectedStocks[_index]["requiredPackages"] = _noPackages;
        this.selectedData.selectedStocks[_index].validate = this.qtyBoxValidation(rowNode);
        this.showBlendQtyDetails();
      }
      if (field == "bags") {
        // Setting Data to UI
        let rowNode = this.gridApi1.getRowNode(event.data.id);
        let rowData = rowNode.data;
        const _requiredQuantity = Math.ceil(parseFloat(rowData.netKgs) * parseFloat(rowData.requiredPackages));
        rowData.requiredPackages = parseFloat(rowData.requiredPackages);
        rowData.requiredQuantity = _requiredQuantity;
        rowNode.setData({ ...rowData });
        let _index = this.selectedData.selectedStocks.findIndex((el) => el.id == rowData.id);
        // Setting Data to Variable
        this.selectedData.selectedStocks[_index]["requiredQuantity"] = _requiredQuantity;
        this.selectedData.selectedStocks[_index]["requiredPackages"] = rowData.requiredPackages;
        this.selectedData.selectedStocks[_index].validate = this.qtyBoxValidation(rowNode);
        this.showBlendQtyDetails();
      }
    },
    async searchData() {
      if (this.selectedData.selectedStocks.length > 0) {
        this.showWarningAlert();
        return;
      }
      this.overlay = true;
      const _data = {
        blendProductionPlanMasterId: this.blendPlan.id,
        usePOStock: this.selectedData.poStockSwitch,
        warehouseIds: this.selectedData.companyWarehouses,
      };

      try {
        this.availableStock = await api.getAvailableStockForProduction(_data);
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
      this.selectedData.selectedStocks = [];
      this.overlay = false;
    },
    async generatePlan() {
      this.overlay = true;
      const _data = {
        blendProductionPlanMasterId: this.blendPlan.id,
        usePOStock: true,
        warehouseIds: this.selectedData.companyWarehouses,
      };
      try {
        this.blendPlanDetails = await api.generateBlendPlan(_data);
        this.mergeDetailsWithStock();
        this.overlay = false;
        this.showSuccessAlert("Plan Generated");
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async saveDetails() {
      this.overlay = true;
      try {
        let validationFail = this.selectedData.selectedStocks.some((el) => el.validate == false);
        // Checking Any Validation Fail
        if (validationFail) {
          this.showErrorAlert("Please Check the Details.");
        } else {
          const result = await api.saveBlendPlanDetail(this.blendPlan.id, this.selectedData.selectedStocks);
          this.isPlanSaved = true;
          this.overlay = false;
          this.showSelectedList = false;
          this.showSuccessAlert("Plan Saved Successfully");
        }
      } catch (error) {
        this.showErrorAlert(error);

        this.overlay = false;
      }
    },
    // /////////////////////////////////

    showSelected() {
      this.selectedData.selectedStocks = this.gridApi.getSelectedRows();

      if (this.selectedData.selectedStocks.length > 0) {
        this.showSelectedList = true;
        this.saveList = this.selectedData.selectedStocks;
      } else {
        this.showWarning("Select Atleast one row...");
      }
    },
    closeListDialogue() {
      this.showSelectedList = false;
    },
    selectOne(obj) {
      this.dialogueRemove = true;
      this.removeObj = obj;
    },
    async allSelectedList(obj) {
      this.dialogueAllRemove = true;
    },
    cancelRemove() {
      this.removeObj = null;
      this.dialogueRemove = false;
      this.saveList = this.selectedData.selectedStocks;
    },
    confirmRemove() {
      let r = this.selectedData.selectedStocks.findIndex((el) => el == this.removeObj);

      this.selectedData.selectedStocks.splice(r, 1);

      this.removeObj = null;
      this.dialogueRemove = false;
    },
    cancelAllRemove() {
      this.dialogueAllRemove = false;
      this.saveList = this.selectedData.selectedStocks;
    },
    confirmAllRemove() {
      this.dialogueAllRemove = false;
      this.showSelectedList = false;
      this.selectedData.selectedStocks = [];
    },
    confirm() {
      console.log(this.saveList);
    },
    // ///////////////////////////////
    async showBlendQtyDetails() {
      // Quantity Change
      let consolidatedSelectedQty = [];

      if (consolidatedSelectedQty.length == 0) {
        this.blendQtyDetails.map((item) => {
          consolidatedSelectedQty.push({
            itemName: item.itemName,
            selectedQty: 0,
            requiredQty: item.requiredQuantity,
          });
        });
      }

      this.selectedData.selectedStocks.map((item) => {
        const Index = consolidatedSelectedQty.findIndex((x) => {
          return x.itemName == item.itemName;
        });

        if (!isNaN(parseFloat(item.requiredQuantity)))
          consolidatedSelectedQty[Index].selectedQty =
            parseFloat(consolidatedSelectedQty[Index].selectedQty) + parseFloat(item.requiredQuantity);
      });
      this.calculatedBlendQtyDetails = [];
      this.calculatedBlendQtyDetails = consolidatedSelectedQty;
    },
    async downloadPlan() {
      await api.downloadBlendPlan(this.blendPlan.id, this.blendPlan.name);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    checkIfTwoWarehousesIncluded() {
      const _wareHouses = [];
      const isPoStockIncluded = false;
      this.blendPlanDetails.forEach((item) => {
        _wareHouses.push(item.companyWarehouseId);
        if (!item.inventoryId) {
          isPoStockIncluded = true;
        }
      });
      this.selectedData.companyWarehouses = [...new Set(_wareHouses.filter((x) => x !== this.blendPlan.companyWarehouseId))];
      this.selectedData.poStockSwitch = isPoStockIncluded;
    },
    onFirstDataRendered(params) {
      params.api.forEachNode((node) => node.setSelected(node.data.isSelected == true));
    },
    mergeDetailsWithStock() {
      this.selectedData.selectedStocks = [];
      this.blendPlanDetails.forEach((item) => {
        // Checking if item is from Inventory Stock or PO Stock
        if (!item.inventoryId) {
          const Index = this.availableStock.findIndex((x) => x.purchaseChildId === item.purchaseChildId);

          if (Index > -1) {
            this.availableStock[Index]["requiredQuantity"] = item.totalNetKgs;
            this.availableStock[Index]["requiredPackages"] = item.noOfPackages;
            this.availableStock[Index].isSelected = true;
            const _item = this.availableStock.find((x) => x.purchaseChildId === item.purchaseChildId);
            this.selectedData.selectedStocks.push(_item);
          }
        } else {
          const Index = this.availableStock.findIndex((x) => x.inventoryId === item.inventoryId);

          console.log(Index);
          if (Index > -1) {
            this.availableStock[Index]["requiredQuantity"] = item.totalNetKgs;
            this.availableStock[Index]["requiredPackages"] = item.noOfPackages;
            this.availableStock[Index].isSelected = true;
            const _item = this.availableStock.find((x) => x.inventoryId === item.inventoryId);
            this.selectedData.selectedStocks.push(_item);
          }
        }
      });
      let selectedData = this.selectedData.selectedStocks;
      this.gridApi.forEachNode(function (node) {
        let indx = selectedData.findIndex((el) => el.inventoryId == node.data.inventoryId);
        if (indx > -1) {
          node.setSelected(true);
        }
      });
    },
    selectionEvent(object) {
      if (!object.value) {
        const _item = this.availableStock.find((x) => x.inventoryId == object.item.inventoryId);
        _item.requiredPackages = "";
        _item.requiredQuantity = "";
      }
    },
    removeEmptyStock() {
      this.selectedData.selectedStocks.forEach((e, index) => {
        if (e.totalNetKgs === 0) {
          this.selectedData.selectedStocks.splice(index, 1);
          const Index = this.availableStock.findIndex((x) => x.inventoryId === e.inventoryId);

          if (Index == -1) return;

          this.availableStock[Index]["requiredPackages"] = "";
          this.availableStock[Index]["requiredQuantity"] = "";
        }
      });
    },
    showWarningAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "Your selected data will be cleared!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "bg-success",
          cancelButton: "btn-primary bg-danger",
        },
        confirmButtonText: "Yes, proceed!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          // TODO Make it better later
          this.overlay = true;
          const _data = {
            blendProductionPlanMasterId: this.blendPlan.id,
            usePOStock: this.selectedData.poStockSwitch,
            warehouseIds: this.selectedData.companyWarehouses,
          };

          this.availableStock = await api.getAvailableStockForProduction(_data);
          this.selectedData.selectedStocks = [];
          this.overlay = false;
          // continue;
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          return;
        }
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 2000,
        icon: "success",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: true,
        type: "error",
        icon: "error",
      });
      this.overlay = false;
    },
    showWarning(message) {
      this.$swal({
        title: "Warning",
        text: message,
        showConfirmButton: false,
        timer: 3000,
        type: "warning",
        icon: "warning",
      });
      this.overlay = false;
    },
    deletePlan() {
      console.log("hi");
    },
    onActionClick(item) {
      if (item === "APPROVE") {
        this.approveBlendPlan();
      } else if (item === "CANCEL") {
        this.cancelBlendPlan();
      }
    },
    async convertToProvisionPlan() {
      try {
        await api.generateProvisionPlan(this.blendPlan.id);
        this.showSuccessAlert("Provisional Plan Generated Successfully");
        this.$router.go(-1);
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async cancelBlendPlan() {
      try {
        await api.cancelBlendPlan(this.blendPlan.id);
        this.showSuccessAlert("Plan Canceled Successfully");
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async reAssignPlan() {
      this.overlay = true;
      try {
        this.blendPlanDetails = await api.reArrangeBlendPlan(this.blendPlan.id);
        this.removeEmptyStock();
        this.mergeDetailsWithStock();
        this.checkIfTwoWarehousesIncluded();
        this.overlay = false;
      } catch (error) {
        console.log(error);
        this.showErrorAlert(error);
      }
    },
    async onInit() {
      this.blendQtyDetails = await api.getBlendPlanQtyDetails(this.blendPlan.id);

      // Getting saved plan details if the plan has already been generated and user is trying to edit the plan
      if (this.blendPlan.planGenerationStatus !== null) {
        // Getting saved Blend plan Details
        this.blendPlanDetails = await api.getBlendPlanDetail(this.blendPlan.id);
        // Checking if the saved plan has multiple warehoused selected,
        // if so filtering and adding them to the selected warehouses.
        this.checkIfTwoWarehousesIncluded();
        // Getting available stock data for the Bland plan
        await this.searchData();
        // Merging Saved blend plan with Available stock to show already selected items
        this.mergeDetailsWithStock();

        this.isPlanSaved = true;
      }
    },
    // Grid Create
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onGridReady1(params) {
      if (this.headers2.length == 11) {
        this.headers2.push({
          headerName: "Required Quantity",
          field: "requiredQuantity",
          cellStyle: (params) => {
            if (this.qtyBoxValidation(params)) {
              return { backgroundColor: "white", color: "black" };
            }
            return { backgroundColor: "pink", color: "black" };
          },
          width: 100,
        });

        this.headers2.push({
          headerName: "Selected No of Packages",
          field: "requiredPackages",

          width: 100,
          cellStyle: (params) => {
            if (this.qtyBoxValidation(params)) {
              return { backgroundColor: "white", color: "black" };
            }
            return { backgroundColor: "pink", color: "black" };
          },
        });
        // Setting Editable field by weight or bags
        if (this.enter_field == "weight") {
          this.headers2[11].editable = true;
          this.headers2[11].onCellValueChanged = async (event) => {
            await this.requiredQtyCalc(event, "weight");
          };
        }
        if (this.enter_field == "bags") {
          this.headers2[12].editable = true;
          this.headers2[12].onCellValueChanged = async (event) => {
            await this.requiredQtyCalc(event, "bags");
          };
        }
      }
      this.gridApi1 = params.api;
      this.gridColumnApi1 = params.columnApi;
    },
    changeEnterField() {
      let vueInstance = this;
      var columnDefs = this.gridApi1.getColumnDefs();

      let idx = 0;
      // Setting Editable field by weight or bags
      if (this.enter_field == "weight") {
        idx = 11;
      }
      if (this.enter_field == "bags") {
        idx = 12;
      }
      columnDefs.forEach(function (colDef, index) {
        if (index == idx) {
          colDef.editable = true;
          colDef.onCellValueChanged = async function (event) {
            await vueInstance.requiredQtyCalc(event, vueInstance.enter_field);
          };
        } else {
          colDef.editable = false;
          // colDef.onCellValueChanged = null;
        }
      });
      this.headers2 = columnDefs;
    },
  },
  created() {
    // For Row wise operations
    this.getRowId = (params) => {
      return params.data.id;
    };
  },
  async mounted() {
    this.blendPlan = this.$route.params.blendPlan;

    this.currentPage = this.$route.params.currentPage;
    if (this.blendPlan.approvalStatus) {
      let _actions = this.$route.params.currentPage.actions_allowed;

      _actions = _actions.filter((x) => x !== "APPROVE");

      this.currentPage["actions_allowed"] = _actions;
    }
    this.companyWarehouses = await CompanyWarehouseService.get();
    this.blendQtyDetails = await api.getBlendPlanQtyDetails(this.blendPlan.id);

    // Getting saved plan details if the plan has already been generated and user is trying to edit the plan
    if (this.blendPlan.planGenerationStatus !== null) {
      // Getting saved Blend plan Details
      this.blendPlanDetails = await api.getBlendPlanDetail(this.blendPlan.id);
      // Checking if the saved plan has multiple warehoused selected,
      // if so filtering and adding them to the selected warehouses.
      this.checkIfTwoWarehousesIncluded();
      // Getting available stock data for the Bland plan
      await this.searchData();
      // Merging Saved blend plan with Available stock to show already selected items
      this.mergeDetailsWithStock();

      this.isPlanSaved = true;

      this.onGridReady(params);
      this.onGridReady1(params);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.ag-theme-alpine {
  /* disable all borders */
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-borders-row: dashed 1px;
  --ag-row-border-color: rgb(150, 150, 200);
  --ag-value-change-value-highlight-background-color: pink;
}

.v-card--link:focus::before {
  opacity: 0;
}
.qtyText {
  width: 65pt;
}
.v-text-field:hover {
  background-color: white;
}
.itemLabel {
  font-weight: 600;
}
.tittle__Row {
  margin-bottom: 1;
}
.label__value {
  font-weight: 600;
}

.v-data-table-header th {
  white-space: nowrap;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}
</style>
