<template>
  <v-dialog persistent v-model="_dialog" max-width="500px">
    <v-card class="card-shadow">
      <v-card-text class="card-padding">
        <v-container class="px-0">
          <!-- #region Card Heading -->
          <div
            class="
              card-header-padding
              d-flex
              align-center
              justify-space-between
            "
          >
            <span class="font-weight-bold text-h5 text-typo mb-0"
              >Quantity Details
            </span>
            <v-btn @click="_dialog = false" text color="error">Close</v-btn>
          </div>
          <v-divider class="mb-5" />
          <!-- #endregion -->

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Item</th>
                  <th class="text-left">Remaining Qty</th>
                  <!-- <th class="text-left">Required Qty</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item.itemName">
                  <td>{{ item.itemName }}</td>
                  <td>
                    {{ Number(item.requiredQty) - Number(item.selectedQty) }}
                  </td>
                  <!-- <td>{{ item.requiredQty }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- #region Card Action buttons -->
          <!-- <v-card-actions class="card-padding d-flex justify-end">
            <v-btn
              @click="_dialog = false"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-light py-3 px-6"
              >Close</v-btn
            >
          </v-card-actions> -->
          <!-- #endregion -->
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BlendPlanQtyDetails",
  props: {
    dialog: Boolean,
    data: Array,
  },
  computed: {
    _dialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
